import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today is our Annual Free Thanksgiving Day WOD at 9:00am at The
Ville! Bring a friend and show up to burn so serious calories before
your Thanksgiving Day meal.  No open gym or additional classes today.
 ALL CLASSES AND OPEN GYM WILL BE BACK TO NORMAL ON FRIDAY OTHER THAN
THE 6AM CLASS AT EAST WHICH IS CANCELLED.`}</strong></p>
    <p>{`Teams of 3-4 people will complete the following in order:`}</p>
    <p>{`1 Mile Row (1600m’s)`}</p>
    <p>{`100 S2OH (135/95, scaled 95/65)`}</p>
    <p>{`200 Burpees`}</p>
    <p>{`300 Wall Balls (20/14, scaled 14/10)`}</p>
    <p>{`400 Situps`}</p>
    <p>{`1 Mile Run.`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`all of this can be broken up among team members but must be done in
order.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      